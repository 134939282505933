<template>
    <nav id="sub-navigation" class="nav nav-tabs mb-3">
        <router-link class="nav-link"
                     active-class="active"
                     :exact="true"
                     v-for="(link, i) in items"
                     :key="'sub-navigation-link-' + i"
                     :to="link.to">
            {{ link.label }}
        </router-link>
    </nav>
</template>

<script>
export default {
    name: "ComponentSubNavigation",
    props: {
        items: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
    #sub-navigation {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            display: block;
            position: absolute;
            height: 2px;
            background-color: var(--bs-light);
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            z-index: -1;
        }

        a {
            padding: .375rem .75rem;
            font-weight: 500;

            &:hover:not(.active) {
                border-color: var(--bs-primary);
            }
        }
    }
</style>